import { render, staticRenderFns } from "./sortOrderSetting.vue?vue&type=template&id=d32e7648&scoped=true&"
import script from "./sortOrderSetting.vue?vue&type=script&lang=js&"
export * from "./sortOrderSetting.vue?vue&type=script&lang=js&"
import style0 from "./sortOrderSetting.vue?vue&type=style&index=0&id=d32e7648&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d32e7648",
  null
  
)

export default component.exports